import Badge from 'components/core/Badge/Badge'
import { theme } from 'components/styles/theme'
import { commonCss } from 'components/styles/util'
import styled, { css } from 'styled-components'
import Button from './Button'

export const BadgeStyled = styled(Badge)`
  font-size: ${theme.sizes.m};
  font-weight: ${theme.weight.l};
`
export const PrimaryBadge = styled(BadgeStyled)`
  background: ${theme.primary};
  color: ${theme.terciary};
`
export const SecondaryBadge = styled(BadgeStyled)`
  background: ${theme.secondary};
  color: ${theme.terciary};
`
export const ButtonStyled = styled(Button)`
  font-size: ${theme.sizes.m};
  font-weight: ${theme.weight.l};
  padding: 1rem 1.4rem;
  border: 0;

  ${commonCss.centerFlex}
  ${commonCss.iconLeftRight}

  &[disabled] {
    background-color: ${theme.gray.p400};
  }
`
export const PrimaryButton = styled(ButtonStyled)`
  background: ${theme.primary};
  color: ${theme.terciary};
`
export const SecondaryButton = styled(ButtonStyled)`
  background: ${theme.secondary};
  color: ${theme.terciary};
`
export const EmptyButton = styled(ButtonStyled)`
  background: transparent;
`


export const TextS = styled.p`
  font-size: ${theme.sizes.s};
  font-weight: ${theme.weight.s};
`
export const TextM = styled.p`
  font-size: ${theme.sizes.m};
  font-weight: ${theme.weight.m};
  padding: 0.25rem 0 1.5rem;
`
export const TextL = styled.p`
  font-size: ${theme.sizes.l};
  font-weight: ${theme.weight.l};
  padding: 0.25rem 0;
`
export const TextXL = styled.p`
  font-size: ${theme.sizes.xl};
  font-weight: ${theme.weight.l};
`

export const Title = styled.p`
  font-size: ${theme.sizes.xxl};
  font-weight: ${theme.weight.l};
  color: ${theme.secondary};
  position: relative;
  &:after {
    content: '';
    border-bottom: 3px solid ${theme.line};
    width: 2.5rem;
    position: absolute;
    left: 0;
    bottom: -12px;
  }
`