import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faCheck, faChevronRight, faEye, faEyeSlash, faHeadphonesAlt, faRandom,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export const icons = {
  check: faCheck,
  radio: faHeadphonesAlt,
  right: faChevronRight,
  hidePass: faEyeSlash,
  insta: faInstagram,
  showPass: faEye,
} 

export const Icon = styled(FontAwesomeIcon)`
  overflow: unset;
`