import React from "react";
import { links } from "views/dataLinks";

import { LinkStyled, LinksWrapperStyled, ListItemStyled, ListStyled, LogoHeaderStyled } from "./styled";


export default function SectionLinks({ logo = false, selected = null }) {
  return (
    <LinksWrapperStyled>
      {logo && <LogoHeaderStyled />}
      <ListStyled>
        {links.map(({ href, text }) => {
          return (
            <ListItemStyled key={href} selected={selected===href}>
              <LinkStyled to={href}>{text}</LinkStyled>
            </ListItemStyled>
          )
        })}
      </ListStyled>
    </LinksWrapperStyled>
  );
}
