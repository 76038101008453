/*eslint-disable*/
import React from "react";

import { CardBackgroundStyled, CardBlogStyled, CardBodyBackgroundStyled, CardBodyStyled, CardHeaderImageStyled, CardStyled } from "./styled";
import CardBody from "components/core/Card/CardBody";


export const Card1 = ({ classes, src, children }) => (
  <CardBlogStyled>
    <CardHeaderImageStyled>
      <img src={src} alt="..." />
      <div
        className={classes.coloredShadow}
        style={{
          backgroundImage: "url(" + src + ")",
          opacity: "1"
        }}
      />
    </CardHeaderImageStyled>
    <CardBodyStyled>
      { children }
    </CardBodyStyled>
  </CardBlogStyled>
)


export const Card2 = ({ classes, src, children, label = null }) => (
  <CardBackgroundStyled>
    <CardHeaderImageStyled>
      <img src={src} alt="..." />
    </CardHeaderImageStyled>
    <CardBodyStyled background>
      { children }
    </CardBodyStyled>
  </CardBackgroundStyled>
)

export const Card1Small = ({ src, alt, children }) => (
  <CardBlogStyled small transparent="true">
    <CardHeaderImageStyled small="true">
      <img src={src} alt={alt} />
    </CardHeaderImageStyled>
    <CardBody>
      { children }
    </CardBody>
  </CardBlogStyled>
)


export const Card2Small = ({ src, children }) => (
  <CardBackgroundStyled src={src}>
    <CardBodyBackgroundStyled>
      { children }
    </CardBodyBackgroundStyled>
  </CardBackgroundStyled>
)

export const CardRawSmall = ({ children }) => (
  <CardStyled>
    <CardBody>
      { children }
    </CardBody>
  </CardStyled>
)