import React from "react";
import { Icon } from "../icons/styled";

const Button = ({
  icon,
  iconRight,
  children = '',
  className,
  onClick,
  disabled
}) => {
  return (
    <button disabled={disabled} className={`${className} ${disabled ? 'disabled' : 'not-disabled'}`} onClick={onClick}>
      { icon && <Icon icon={icon} />}
      <span>{ children }</span>
      { iconRight && <Icon icon={iconRight} />}
    </button>
  );
}

export default Button