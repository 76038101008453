import styled, { css } from 'styled-components';
import { grayColor, whiteColor } from 'components/styles/constants';
import Card from "components/core/Card/Card";
import CardBody from "components/core/Card/CardBody";
import CardAvatar from "components/core/Card/CardAvatar";
import CardHeader from "components/core/Card/CardHeader";

export const CardAvatarProfileStyled = styled(CardAvatar).attrs(({
  profile: true
}))`
`
export const CardStyled = styled(Card)`
  color: ${grayColor[3]};
  & h4 {
    color: ${grayColor[1]};
    font-size: 1.2rem;
    padding: 0 0 0 1rem;
  }
  & ul {
    padding: 0 0 0 1rem;
  }
  & li::marker {
    font-size: 110%;
  }
  & li p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
  }
  & li li p {
    text-align: left;
    font-size: 0.8rem;
    padding-bottom: 0.25rem;
  }
`
export const CardNegativeStyled = styled(Card)`
  color: ${whiteColor};
  & h4 {
    color: ${whiteColor};
  }
`
export const CardBlogStyled = styled(CardStyled).attrs(({
  blog: true
}))`
  display: flex;
  align-items: center;

  ${({ transparent }) => transparent && css`
    box-shadow: none;
    background: none;
  `}

  ${({ theme, small }) => !small && css`
    ${theme.breakpoints.up('md')} {
      display: grid;
      grid-template-columns: 23rem 1fr;
    }
  `}
`
export const CardContactStyled = styled(CardStyled).attrs(({
  blog: true
}))`
  display: flex;
  align-items: center;

  border: 1px solid;
  padding: 3rem 1rem;
  margin-top: 0;
  z-index: 2;

  & img {
    position: absolute;
    height: 10rem;
    right: 0;
    top: -5rem;
  }
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 1rem 0rem;

      & img {
        height: 8rem;
      }
    }
  `}


`
export const CardBodyBingoStyled = styled(CardBody)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 1rem 0.5rem;
    }
  `}
`
export const CardBodyContactStyled = styled(CardBody)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 1rem 0.5rem;
    }
  `}
`
export const CardBackgroundStyled = styled(CardNegativeStyled).attrs(({
  background: true
}))`
  background-color: ${grayColor[1]};
  
  ${({ src }) => css`
    background-image: url(${src});
  `}

  ${({ theme, small }) => !small && css`
    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
    }
  `}
`
export const CardBodyStyled = styled(CardBody).attrs(({
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const CardBodyBackgroundStyled = styled(CardBody).attrs(({
  background: true
}))`
  padding: 0.5rem;
`
export const CardHeaderImageStyled = styled(CardHeader).attrs(({
  image: true
}))`

  ${({ theme }) => css`
    z-index: 2;
    ${theme.breakpoints.up('sm')} {
      ${({ small }) => small && css`
        height: 9rem;
        width: 14rem;
        overflow: hidden;
      `}
      width: 21rem;
    }
  `}

  background: ${whiteColor};
  border: 1px solid ${grayColor[6]};
  display: flex;
`
export const CardProfileStyled = styled(Card).attrs(({
  profile: true
}))`
  color: ${grayColor[3]};
  & h4 {
    color: ${grayColor[3]};
  }
`
