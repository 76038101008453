import { px2rem } from "components/styles/util";

export const theme = {
  primary: '#FF0049',
  secondary: '#002B45',
  secondaryLight: '#808B96',
  terciary: '#FFF1E5',
  line: '#009dff',
  error: '#E74C3C',
  white: '#FFFFFF',
  black: '#000000',
  focus: '#E67E22',
  gray: {
    p50: '#fafafa',
    p100: '#f5f5f5',
    p200: '#eee',
    p300: '#e0e0e0',
    p400: '#bdbdbd',
    p500: '#9e9e9e',
    p600: '#757575',
    p700: '#616161',
    p800: '#424242',
    p900: '#212121'
  },
  sizes: {
    s: px2rem(12),
    m: px2rem(14),
    l: px2rem(16),
    xl: px2rem(20),
    xxl: px2rem(24)
  },
  weight: {
    s: 200,
    m: 400,
    l: 600,
  },
  padding: i => px2rem(i * 16)
};