import { icons } from "components/icons/styled"
import images from "components/styles/images"

const media = [
  {
    href:'https://www.ondaceromadridsur.es/todos-podcasts/8951',
    title: 'AlianZas y jóvenes en Torrejón de la Calzada',
    text: 'Escuchar la entrevista completa en Onda Cero Madrid Sur el 25 de febrero',
    icon: icons.radio,
    src: images.media.rJovenes,
    small: true,
  },
  {
    href:'https://www.instagram.com/p/CZ_9LdpMJfz/',
    title: 'Asamblea de discusión',
    text: 'Viernes 18 de febrero en el Centro Juvenil de Torrejón de la Calzada ¡Te esperamos!',
    icon: icons.insta,
    src: images.media.iOir
  },
  {
    href:'https://www.instagram.com/p/CZ7I19ktnEE/',
    title: 'Diagnóstico juvenil en Torrejón de la Calzada',
    text: 'Proyecto en colaboración con el Ayuntamiento de Torrejón de la Calzada mediante técnicas de investigación cualitativa y cuantitativa, para dar luz de realidad a la situación...',
    icon: icons.insta,
    src: images.media.iDiagnostico,
    small: true,
  },
  {
    href:'https://www.instagram.com/p/CY9PcoqNd6d/',
    title: 'Nuestros servicios',
    icon: icons.insta,
    src: images.media.iServicios
  }
]
export {
  media,
}